import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Image from 'next/image';
export const partnerTeamToProps = ({ team, hideTvProviderDisclaimer = false, }) => {
    return {
        alt: team.name || '',
        title: team.name,
        subtitle: `${team.league}${hideTvProviderDisclaimer || team.dtc ? '' : ' • TV Provider Only'}`,
        lightSrc: team.light_image || '',
        darkSrc: team.dark_image || '',
    };
};
export const cmsRegionTeamToProps = ({ team, hideTvProviderDisclaimer = false, }) => {
    return {
        alt: team.short_name || '',
        title: team.short_name,
        subtitle: `${team.league}${hideTvProviderDisclaimer || team.DTCavailable ? '' : ' • TV Provider Only'}`,
        lightSrc: team.light_image || '',
        darkSrc: team.dark_image || '',
    };
};
export const logoLeagueToProps = ({ team = 'logo', light_logo = '', dark_logo = '', name = '', }) => {
    return { alt: team, lightSrc: light_logo, darkSrc: dark_logo, title: name };
};
// TODO: Automatically add data-attribute and name based on component name
export const LogoWithDisc = ({ alt, lightSrc, darkSrc, altBg = true, direction = 'row', title, subtitle, className, }) => {
    return (_jsxs("div", { "data-component-name": "LogoWithDisc", className: clsx('max-content inline-flex items-center', direction === 'row' ? 'gap-2' : 'gap-0', {
            'flex-col': direction === 'col',
        }), children: [_jsxs("div", { className: clsx(className, 'w-fit p-[15px] size-auto rounded-full ', { 'bg-bg-alt': altBg }, { 'bg-bg': !altBg }), children: [_jsx(Image, { alt: alt, src: lightSrc, width: "26", height: "26", className: "block dark:hidden" }), _jsx(Image, { alt: alt, src: darkSrc, width: "26", height: "26", className: "dark:block hidden" })] }), _jsxs("div", { className: clsx('flex flex-col justify-center', { 'items-center': direction === 'col' }), children: [title ? _jsx("p", { className: "text-fg", children: title }) : null, subtitle ? _jsx("p", { className: "text-fg-alt text-sm", children: subtitle }) : null] })] }));
};
