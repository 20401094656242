import { breakpoints } from '@/styles/breakpoints'
import styled from 'styled-components'

export const StickyNavWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 3000;
`
export const StaticNavWrapper = styled.div`
  width: 100%;
`
export const NavBarContainer = styled.div`
  width: 100%;
  max-width: var(--container-max-width);
  padding: 0;
  margin: 0 auto;
  background-color: var(--canvas);

  ${breakpoints.up('desktop')} {
    padding: 0 var(--container-spacer);
  }
`
