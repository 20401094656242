import Toast, { ToastProps } from '@/components/Toast'
import { ReactNode, createContext, useState } from 'react'

interface ToastContextProps {
  showToast: (toastProps: ToastProps) => void
}

export const ToastContext = createContext<ToastContextProps>({
  showToast: () => {},
})

interface ToastProviderProps {
  children: ReactNode
}

export const ToastContextProvider = ({ children }: ToastProviderProps) => {
  const [toastMessage, setToastMessage] = useState<ReactNode>()
  const [toastState, setToastState] = useState<ToastProps['state']>('success')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [showIconRight, setShowIconRight] = useState<boolean>(false)
  const [showIconLeft, setShowIconLeft] = useState<boolean>(false)
  const [showLink, setShowLink] = useState<ToastProps['link']>('')
  const [lastToastTime, setLastToastTime] = useState<number>(0)

  const showToast = (toastProps: ToastProps) => {
    const { state, children, iconRight, iconLeft, link } = toastProps

    const currentTime = Date.now()
    const cooldown = 5500
    if (currentTime - lastToastTime < cooldown) {
      return
    }
    setLastToastTime(currentTime)

    setToastState(state)
    setToastMessage(children)
    iconRight && setShowIconRight(true)
    iconLeft && setShowIconLeft(true)
    link && setShowLink(link)

    setToastVisible(true)

    setTimeout(() => {
      setToastVisible(false)
    }, 8000)
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toastVisible && (
        <Toast state={toastState} iconLeft={showIconLeft} iconRight={showIconRight} link={showLink}>
          {toastMessage}
        </Toast>
      )}
    </ToastContext.Provider>
  )
}

export default ToastContextProvider
