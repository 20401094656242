import { GrandstandButton } from '@/components/Buttons'
import { useTopNavStore } from '@/components/TopNav/TopNavContext'
import { StickyNavWrapper } from '@/components/TopNav/styles'
import { breakpoints } from '@/styles/breakpoints'
import { SubheadingLabel } from '@/styles/fonts'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { GrandstandLogo } from '@grandstand-web/bally-web-shared/src/components/GrandstandLogo'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { cmsRegionTeamToProps, LogoWithDisc } from '@grandstand-web/ui'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'

export const NavTopPortalWrapper = styled(StickyNavWrapper)`
  top: 0;
  background-color: var(--canvas);
`
const Container = styled.div<{
  paddingY: string
  widthMax: string
}>`
  width: 100%;
  max-width: ${(props) => props.widthMax};
  margin: 0 auto;
  background-color: var(--canvas);
  padding: ${({ paddingY }) => paddingY} 0;
  ${breakpoints.up('desktop')} {
    padding: ${({ paddingY }) => paddingY} var(--container-spacer);
  }
`

const NavGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 72px;
  gap: 0 0;
  ${breakpoints.up('desktop')} {
    grid-template-columns: 1.5fr 7fr 1.5fr;
    grid-template-rows: 72px;
  }
`

const NavItem = styled.div<{ fade?: number }>`
  display: flex;
  align-items: center;
  height: 72px;
  padding: 8px var(--mobile-container-spacer);
  opacity: ${(props) => (props?.fade === undefined ? 1 : props.fade)};
  background-color: var(--canvas);
  overflow: hidden;
  ${breakpoints.up('tablet')} {
    padding: 8px var(--tablet-container-spacer);
  }
  ${breakpoints.up('desktop')} {
    padding: 8px 0;
  }
`
// NavItem variants with Brand/ BrandLink
const NavItemStart = styled(NavItem)`
  grid-column-start: 1;
  ${breakpoints.up('desktop')} {
    padding-left: 0;
  }
`

const NavItemEnd = styled(NavItem)`
  grid-column-start: 2;
  justify-content: flex-end;
  ${breakpoints.up('desktop')} {
    grid-column-start: 3;
    padding-right: 0;
  }
`
const NavItemContent = styled(NavItem)<{ isLoggedIn: boolean }>`
  ${({ isLoggedIn }) => (isLoggedIn ? authdNavItemContentStyles : unauthdNavItemContentStyles)}
  display: flex;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  opacity: ${({ fade, isLoggedIn }) => (isLoggedIn || fade === undefined ? 1 : fade)};
  border-top: 1px solid var(--divider-canvas);
  background-color: var(--canvas);
  overflow-y: hidden;
  overflow-x: auto;
  grid-row-start: 1;
  grid-column: 1 / span 2;
  position: absolute;
  top: 100%;
  ${breakpoints.up('lg')} {
    position: static;
    top: unset;
    grid-row-start: 1;
    grid-column: 2 / span 1;
    min-height: 100%;
    justify-content: center;
    overflow-x: hidden;
    border: none;
    position: static;
  }
`

/// END NAV ITEMS

const BrandLink = styled(Link)`
  --brand-height: 32px;

  display: block;
  position: relative;
  height: var(--brand-height);
  width: auto;
  ${breakpoints.up('tablet')} {
    --brand-height: 40px;
  }
`

const BrandLogos = styled.div<{ fade: number }>`
  z-index: 1;
  div,
  svg {
    width: auto;
    height: var(--brand-height);
    path {
      opacity: ${({ fade }) => fade};
      &.b {
        opacity: 1;
      }
    }
  }
`

// Content NavItem (has team list)

const authdNavItemContentStyles = css`
  grid-column: 1 / span 2;
  grid-row-start: 2;

  ${breakpoints.up('lg')} {
    grid-row-start: 1;
    grid-column: 2 / span 1;
    min-height: 100%;
    justify-content: center;
    overflow-x: hidden;
    border: none;
    position: static;
  }
`
const unauthdNavItemContentStyles = css`
  position: absolute;
  top: var(--item-height);
  left: 0;
  right: 0;
`

// TeamList
const TeamList = styled.div`
  --logo-size: 56px;
  --image-size: 36px;
  display: flex;
  align-items: center;

  padding: 0 16px;
  gap: 0 8px;
  color: var(--on-background);
  margin: 0 auto;
  ${breakpoints.up('lg')} {
    --logo-size: 56px;
    --image-size: 34px;
    padding: 0 16px;
    gap: 0 16px;
    justify-content: center;
  }
`
const TeamItem = styled.div`
  color: var(--on-background);
  position: relative;
`

const TeamLink = styled(Link)``

const TeamLogo = styled(Image)`
  position: static !important;
  width: var(--image-size) !important;
  height: var(--image-size) !important;
  object-fit: cover !important;
`

// Profile NavItem: Link to /settings + display name

const Profile = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 220px;
  color: var(--on-background);
  text-decoration: none;
`

const ProfileIcon = styled.div`
  padding: 0;
  margin: 0 0 0 auto;
  ${breakpoints.up('md')} {
    margin: 0;
  }
`
const ProfileContent = styled.div`
  display: none;
  ${breakpoints.up('md')} {
    width: 100%;
    padding: 0 1rem 0 0;
    margin: 0 0 0 auto;
    width: auto;
    display: block;
  }
`
const ProfileLabel = styled(SubheadingLabel)`
  height: auto;
  max-height: 56px;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: 8px;
`

export const NavTop = ({
  startItem,
  endItem,
  hideTeams,
  containerMaxWidth,
}: {
  startItem?: ReactNode | undefined
  endItem?: ReactNode | undefined
  hideTeams?: boolean
  containerMaxWidth?: string
}) => {
  const store = useTopNavStore()
  const userService = useContext(UserServiceContext)
  return (
    <Container paddingY={`${store.fadeOut * 20}px`} widthMax={containerMaxWidth ?? 'var(--container-max-width)'}>
      <NavGrid>
        <NavItemStart ref={store.startItemRef}>
          {startItem ?? (
            <BrandLink href="/" title="FanDuel Sports Network">
              <BrandLogos fade={10}>
                <GrandstandLogo width="auto" logoStyle="horiz" aria-hidden={true} />
              </BrandLogos>
            </BrandLink>
          )}
        </NavItemStart>

        <NavItemEnd>
          {endItem ? (
            <>{endItem}</>
          ) : store.isLoggedIn ? (
            <Profile href="/settings">
              <ProfileContent>
                <ProfileLabel fontSize="1">
                  {store.name ? (
                    <span>
                      Welcome Back, <br />
                      {store.name}!
                    </span>
                  ) : (
                    <span>Welcome Back!</span>
                  )}
                </ProfileLabel>
              </ProfileContent>
              <ProfileIcon>
                <GrandstandIcon icon="account" />
              </ProfileIcon>
            </Profile>
          ) : (
            <Link href="/signin">
              <GrandstandButton buttonSize="sm" buttonStyle="alt">
                Sign&nbsp;In
              </GrandstandButton>
            </Link>
          )}
        </NavItemEnd>

        {store.availableTeams?.length && !hideTeams ? (
          <NavItemContent fade={store.isLoggedIn ? 1 : store.fadeIn} isLoggedIn={store.isLoggedIn}>
            <TeamList>
              {store.availableTeams
                .toSorted((a, b) => {
                  const aInFavorites = userService.isInFavorites(a.sport_radar_id)
                  const bInFavorites = userService.isInFavorites(b.sport_radar_id)
                  if (aInFavorites && !bInFavorites) {
                    return -1
                  } else if (!aInFavorites && bInFavorites) {
                    return 1
                  } else {
                    return 0
                  }
                })
                .map((team) => {
                  const { short_name, slug, light_image, dark_image } = team
                  const imageSrc = store.isDark ? dark_image : light_image
                  const isFavorite = userService.isInFavorites(team.sport_radar_id)
                  return (
                    <TeamItem key={`nav-top-team-item-${slug}`}>
                      <TeamLink href={`/teams/${slug}`} title={`${short_name ?? ''} Team Hub`}>
                        <LogoWithDisc altBg={true} {...cmsRegionTeamToProps({ team })} title="" subtitle="" />
                        {isFavorite && (
                          <IconWrapper>
                            <GrandstandIcon icon="favorite_active" width="15px" />
                          </IconWrapper>
                        )}
                      </TeamLink>
                    </TeamItem>
                  )
                })}
            </TeamList>
          </NavItemContent>
        ) : null}
      </NavGrid>
    </Container>
  )
}
