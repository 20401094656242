import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { ApiClient } from '@grandstand-web/bally-web-shared/src/newPackages/ApiClient'
import currentUserStore from '@grandstand-web/bally-web-shared/src/newPackages/StorageProviders/currentUserStore'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { useTealium } from '@grandstand-web/bally-web-shared/src/services/tealium/TealiumService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { AuthProfileOutput, UserEntitlement } from '@grandstand/presentation-models'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'

const logger = Logger.of('CaptivateAnalytics')
const CaptivateAnalytics = () => {
  const analyticService = useContext(AnalyticServiceContext)
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [captivateKey] = useLocalStorage('captivateKey', '')
  const { isLoggedIn } = useContext(UserServiceContext)
  const router = useRouter()
  const sendingAnalytics = useRef(false)
  const tealium = useTealium()

  useEffect(() => {
    const { key, operation, status, pauseStartDate, pauseEndDate } = router.query
    const isValidCaptivateKey = key === captivateKey
    const currentUser = currentUserStore.getItem()
    const currentIsLoggedIn = currentUser?.profile.internal?.email ? true : false
    if (
      !currentUser ||
      !isLoggedIn ||
      !currentIsLoggedIn ||
      !router.isReady ||
      !operation ||
      sendingAnalytics.current
    ) {
      return
    }
    sendingAnalytics.current = true
    const getUser = (): AuthProfileOutput => currentUserStore.getItem() ?? currentUser

    const entitlementsForUser = (
      user: AuthProfileOutput
    ): { entitlement: string; active: UserEntitlement[]; paused: UserEntitlement[] } => ({
      entitlement: user.analytics.tealium.entitlement,
      active: user.profile.entitlements,
      paused: user.profile.paused_entitlements,
    })

    const prevEntitlements = entitlementsForUser(getUser())

    const pollForChangedEntitlements = async (): Promise<AuthProfileOutput> =>
      new Promise((resolve) => {
        let attempt = 1
        const maxAttempts = 5

        const interval = setInterval(async () => {
          await ApiClient.forceRefresh()

          const refreshedUser = getUser()
          const nextEntitlements = entitlementsForUser(refreshedUser)

          if (
            nextEntitlements.entitlement !== prevEntitlements.entitlement ||
            nextEntitlements.active.length !== prevEntitlements.active.length ||
            nextEntitlements.paused.length !== prevEntitlements.paused.length ||
            attempt >= maxAttempts
          ) {
            clearInterval(interval)
            resolve(refreshedUser)
          }

          attempt += 1
        }, 1000)
      })

    const removeQueryParams = () => {
      if (key && operation && status) {
        router.replace({ pathname: router.asPath.split('?')[0] }, undefined, { shallow: true })
      }
    }

    const sendAnalytics = async (): Promise<void> => {
      if (status !== 'completed' || !isValidCaptivateKey || !operation) {
        return removeQueryParams()
      }
      const refreshedUser = await pollForChangedEntitlements()
      const entitlement = refreshedUser.analytics.tealium.entitlement
      if (operation === 'cancel') {
        analyticService.cancelledSubscription()
        tealium.cancelSubscription({
          entitlement,
        })
      }
      if (operation === 'pause' && typeof pauseStartDate === 'string' && typeof pauseEndDate === 'string') {
        const duration = Math.round(
          moment(new Date(parseInt(pauseEndDate))).diff(new Date(parseInt(pauseStartDate)), 'months', true)
        ).toString()

        analyticService.pauseSubscription({ duration })
        tealium.pauseSubscription({
          pause_duration: duration,
          entitlement,
        })
      }

      return removeQueryParams()
    }
    sendAnalytics()
  }, [router, analyticService, captivateKey, tealium, currentConfig, isLoggedIn])
  return null
}
export default CaptivateAnalytics
