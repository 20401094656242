import { JWTToken } from '@grandstand/presentation-models'
import { Logger } from '../../../utils/logger'
import { CurrentUser } from '../../StorageProviders/currentUserStore'
import { tokenRefreshBlockingFlag } from './tokenRefreshBlockingFlag'

const createUnauthenticatedUserLogger = Logger.of('createUnauthenticatedUser')

interface CreateUnauthenticatedUserOpts {
  anonymousUrl: string
  setCurrentUser(response: CurrentUser): void
}

export const createUnauthenticatedUser = async ({
  anonymousUrl,
  setCurrentUser,
}: CreateUnauthenticatedUserOpts): Promise<JWTToken> => {
  createUnauthenticatedUserLogger.info('init')
  if (tokenRefreshBlockingFlag.isBlocking()) {
    createUnauthenticatedUserLogger.info('token blocking flag active')
    return ''
  }
  createUnauthenticatedUserLogger.info('token creating')
  let user: CurrentUser
  try {
    tokenRefreshBlockingFlag.setBlockingFlag()
    const response = await fetch(anonymousUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_type: 'ip' }),
    })
    user = await response.json()
    setCurrentUser(user)
    createUnauthenticatedUserLogger.info('token created')
  } catch (e) {
    createUnauthenticatedUserLogger.info('error', e)
  } finally {
    tokenRefreshBlockingFlag.clearBlockingFlag()
  }
  return user?.user_token || ''
}
