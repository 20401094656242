import { datadogLogs } from '@datadog/browser-logs'

// This flag is stored locally so they don't have to be evaluated every time
let _shouldUseExternalLogger: boolean | null = null
export const shouldUseExternalLogger = () => {
  if (_shouldUseExternalLogger !== null) {
    return _shouldUseExternalLogger
  }
  const config = datadogLogs.getInitConfiguration()
  _shouldUseExternalLogger = config?.forwardConsoleLogs !== 'all'
  return _shouldUseExternalLogger
}
