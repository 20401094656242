export type AppName = 'web' | 'connected-web'

export type EnvironmentInt = 0 | 6 | 8

export type ClientRuntimeConfig = {
  appName: AppName
  rootVersion: string
  buildNumber: string
  gtmId: string
  environment: EnvironmentInt
  dataDogClientToken: string
  dataDogRumApplicationId: string
  dataDogRumClientToken: string
  deploymentUrl: string
  isEphemeral: boolean
}

export const clientRuntimeConfig = {
  appName: process.env.NEXT_PUBLIC_APP_NAME,
  rootVersion: process.env.NEXT_PUBLIC_APP_VERSION || '',
  gitHash: process.env.NEXT_PUBLIC_GITHASH,
  buildNumber: process.env.NEXT_PUBLIC_BUILD_NUMBER,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
  environment: Number(process.env.NEXT_PUBLIC_CONFIGAPI_ENVIRONMENT),
  dataDogClientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN || '',
  dataDogRumApplicationId: process.env.NEXT_PUBLIC_DATA_DOG_RUM_APPLICATION_ID || '',
  dataDogRumClientToken: process.env.NEXT_PUBLIC_DATA_DOG_RUM_CLIENT_TOKEN || '',
  deploymentUrl: process.env.NEXT_PUBLIC_DEPLOYMENT_URL || '',
  isEphemeral: process.env.NEXT_PUBLIC_IS_EMPHEMERAL === 'true',
}
