import { ColorSchemeState, useColorScheme } from '@/hooks/useColorScheme'
import { UserService, UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { isValidZipcode } from '@grandstand-web/bally-web-shared/src/utils/stringUtils'
import { CMSRegionTeam } from '@grandstand/presentation-models'
import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
export type SafeArea = {
  top: number
  bottom: number
  left: number
  right: number
  height: number
  width: number
}
type TopNavContextType = {
  //   userService: UserService
  colorScheme: ColorSchemeState
  isDark: boolean
  isLoggedIn: boolean
  isInMarket: boolean
  isDTC: boolean
  name: string
  availableTeams: CMSRegionTeam[]
  zipcode: string
  setZipcode: (zipCode: string) => void
  topRef: RefObject<HTMLDivElement>
  topHeight: number
  setTopHeight: Dispatch<SetStateAction<number>>
  mainRef: RefObject<HTMLDivElement>
  mainHeight: number
  setMainHeight: Dispatch<SetStateAction<number>>
  bottomRef: RefObject<HTMLDivElement>
  bottomHeight: number
  setBottomHeight: Dispatch<SetStateAction<number>>
  startItemRef: RefObject<HTMLDivElement>
  startItemHeight: number
  setStartItemHeight: Dispatch<SetStateAction<number>>
  scrollY: number
  setScrollY: Dispatch<SetStateAction<number>>
  ready: boolean
  fadeIn: number
  fadeOut: number
  safeAreaTop: number
}

export const TopNavContext = createContext<TopNavContextType>(undefined as any)
export const useTopNavProviderValue = (currentUserService?: UserService): TopNavContextType => {
  const { currentUser, updateZipcode, isLoggedIn, isInMarket, isDTCEntitled } = useContext(UserServiceContext)
  const colorScheme = useColorScheme()
  const isDark = !!colorScheme.isDark
  const isDTC = !!isDTCEntitled()
  const name = currentUser?.profile?.internal?.name ?? ''
  const availableTeams = currentUser?.profile?.region.all_regional_teams ?? []
  // update zipcode on remote userService when zipcode state changes
  const setZipcode = useCallback(
    (zipcode: string) => {
      if (!currentUser) {
        return () => {}
      }
      const updateZip = async () => {
        if (
          isValidZipcode(zipcode) &&
          zipcode != currentUser?.profile?.region.zip_code &&
          !currentUser?.profile.region.is_locked
        ) {
          updateZipcode(zipcode)
        }
      }
      updateZip()
      return () => {}
    },
    [currentUser, updateZipcode]
  )

  // ···································································
  // SCROLL + FADE
  // ···································································
  const topRef = useRef<HTMLDivElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const startItemRef = useRef<HTMLDivElement>(null)

  const [topHeight, setTopHeight] = useState<number>(0)
  const [mainHeight, setMainHeight] = useState<number>(0)
  const [bottomHeight, setBottomHeight] = useState<number>(0)
  const [startItemHeight, setStartItemHeight] = useState<number>(0)
  const [scrollY, setScrollY] = useState<number>(0)
  const [safeAreaTop, setSafeAreaTop] = useState<number>(0)

  const [ready, setReady] = useState<boolean>(false)
  const fadeIn = useMemo(() => {
    let rawFadeIn: number = 0
    const height = isLoggedIn ? Math.max(topHeight, 50) : Math.max(mainHeight, 150)
    const minScrollY = isLoggedIn ? height / 5 : Math.round((height / 10) * 1.5)
    const maxScrollY = isLoggedIn ? height * 5 : Math.round((height / 10) * 6.5)
    if (!ready) {
      rawFadeIn = 0
    } else {
      rawFadeIn = scrollY < minScrollY ? 0 : Math.round((scrollY / maxScrollY) * 1000) / 1000
    }
    const nextFadeIn = Math.max(0, Math.min(rawFadeIn, 1))
    return nextFadeIn
  }, [isLoggedIn, topHeight, mainHeight, ready, scrollY])

  const fadeOut = useMemo(() => Math.max(1, fadeIn) - Math.min(1, fadeIn), [fadeIn])

  const updateStates = () => {
    if (
      typeof window === 'undefined' ||
      topRef.current?.clientHeight === undefined ||
      mainRef.current?.clientHeight === undefined ||
      bottomRef.current?.clientHeight === undefined ||
      startItemRef.current?.clientHeight === undefined
    ) {
      return
    }
    const next = {
      topHeight: topRef.current.clientHeight,
      mainHeight: mainRef.current.clientHeight,
      bottomHeight: bottomRef.current.clientHeight,
      startItemHeight: startItemRef.current.clientHeight,
      scrollY: window.scrollY,
    }

    // set dimension states
    setTopHeight(next.topHeight)
    setMainHeight(next.mainHeight)
    setBottomHeight(next.bottomHeight)
    setStartItemHeight(availableTeams.length > 0 ? next.startItemHeight : 0)
    setScrollY(next.scrollY)
    setSafeAreaTop(window.innerWidth < 1280 ? next.startItemHeight : 0)
    setReady(true)
  }

  useEffect(() => {
    updateStates()

    // listen for scroll/resize, and update
    window.addEventListener('scroll', updateStates)
    window.addEventListener('resize', updateStates)

    return () => {
      window.removeEventListener('scroll', updateStates)
      window.removeEventListener('resize', updateStates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch TopSection, MainSection elements to update heights + scrollY
  useEffect(() => {
    updateStates()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topRef.current?.clientHeight,
    mainRef.current?.clientHeight,
    bottomRef.current?.clientHeight,
    startItemRef.current?.clientHeight,
    setTopHeight,
    setMainHeight,
    setBottomHeight,
    setStartItemHeight,
    setScrollY,
    setSafeAreaTop,
    setReady,
  ])

  return {
    colorScheme,
    isDark,
    isLoggedIn,
    isInMarket,
    isDTC,
    name,
    availableTeams,
    zipcode: currentUser?.profile.region.zip_code ?? '',
    setZipcode: setZipcode,
    topRef,
    topHeight,
    setTopHeight,
    mainRef,
    mainHeight,
    setMainHeight,
    bottomRef,
    bottomHeight,
    setBottomHeight,
    startItemRef,
    startItemHeight,
    setStartItemHeight,
    scrollY,
    setScrollY,
    ready,
    fadeIn,
    fadeOut,
    safeAreaTop,
  }
}

export type TopNavProviderType = PropsWithChildren<{}>
export const TopNavProvider = ({ children }: TopNavProviderType) => {
  const store = useTopNavProviderValue()
  return <TopNavContext.Provider value={store}>{children}</TopNavContext.Provider>
}

export const useTopNavStore = () => {
  const store = useContext(TopNavContext)
  return store
}
