import { datadogLogs } from '@datadog/browser-logs'
import { shouldUseExternalLogger } from './shouldUseExternalLogger'
import { ExternalLogger } from './types'

let _externalLogger: ExternalLogger | null = null

const createExternalLogger = (): ExternalLogger => {
  return datadogLogs.createLogger('grandstandLogger', { handler: 'http' })
}

export const getExternalLogger = (): ExternalLogger | null => {
  if (!shouldUseExternalLogger()) {
    return null
  }
  if (_externalLogger === null) {
    const config = datadogLogs.getInitConfiguration()
    if (config?.forwardConsoleLogs !== 'all') {
      _externalLogger = createExternalLogger()
    }
  }
  return _externalLogger
}
