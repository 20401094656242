import { ApiClient } from '..'
import { Logger } from '../../../utils/logger'
import { CurrentUser } from '../../StorageProviders/currentUserStore'
import { tokenRefreshBlockingFlag } from './tokenRefreshBlockingFlag'

interface LogoutOpts {
  logoutUrl: string
  setCurrentUser(response: CurrentUser): void
}

const logoutLogger = Logger.of('logout')

export const logout = async ({ logoutUrl, setCurrentUser }: LogoutOpts): Promise<void> => {
  logoutLogger.info('init')
  await tokenRefreshBlockingFlag.waitForBlockingFlagClear()
  logoutLogger.info('blocking flag clear, proceeding to logout')
  try {
    tokenRefreshBlockingFlag.setBlockingFlag()
    const response = await ApiClient.convenientApiFetch({
      url: logoutUrl,
      method: 'DELETE',
    })
    const user = await response.json()
    setCurrentUser(user)
    logoutLogger.info('token refreshed')
  } catch (e) {
    logoutLogger.info('error', e)
  } finally {
    tokenRefreshBlockingFlag.clearBlockingFlag()
  }
  return
}
