import { initDeviceInfo } from '@grandstand-web/device-info/src/index'
import React, { useState } from 'react'
import { AnalyticServiceProvider } from './analytics/AnalyticService'
import { ConfigServiceProvider } from './config/ConfigService'
import { TealiumServiceProvider } from './tealium/TealiumService'
import { UserServiceProvider } from './user/UserService'

type ServiceProviderProps = {
  gtmId: string
  app: 'web' | 'connected-web'
}

export const ServiceProvider = ({ children, gtmId, app }: React.PropsWithChildren<ServiceProviderProps>) => {
  const [isDeviceInfoLoaded, setIsDeviceInfoLoaded] = useState(false)

  if (!isDeviceInfoLoaded) {
    initDeviceInfo().then(() => {
      setIsDeviceInfoLoaded(true)
    })
    return <></>
  }

  return (
    <ConfigServiceProvider apiPlatform={app === 'connected-web' ? 'cw' : 'web'}>
      <UserServiceProvider app={app}>
        <TealiumServiceProvider app={app}>
          <AnalyticServiceProvider app={app} gtmId={gtmId}>
            {children}
          </AnalyticServiceProvider>
        </TealiumServiceProvider>
      </UserServiceProvider>
    </ConfigServiceProvider>
  )
}
