import { JWTToken } from '@grandstand/presentation-models'
import jwt from 'jsonwebtoken'
import { Logger } from '../../../utils/logger'
import { calculateRefreshTimestamp } from './calculateRefreshTimestamp'

interface IsTokenReachingExpirationOpts {
  token: JWTToken
  debug: boolean
}

const isTokenReachingExpirationLogger = Logger.of('isTokenReachingExpiration')

export const isTokenReachingExpiration = ({ token, debug }: IsTokenReachingExpirationOpts): boolean => {
  isTokenReachingExpirationLogger.info('init')
  const decodedToken = jwt.decode(token, { json: true })
  // Assumption: if anything is wrong with the token, the user is logged out or in an error state
  // We may want to throw an error for this
  if (decodedToken === null) {
    isTokenReachingExpirationLogger.error('token is null')
    return false
  }
  const { exp: expirationTs } = decodedToken
  if (expirationTs === undefined) {
    isTokenReachingExpirationLogger.error('Token exp property is missing', decodedToken)
    return false
  }
  const refreshTime = calculateRefreshTimestamp(expirationTs, debug)
  const now = Date.now()
  const isTokenReachingExpiration = now.valueOf() >= refreshTime.valueOf()
  isTokenReachingExpirationLogger.info(`outcome: ${isTokenReachingExpiration}`, {
    exp: new Date(expirationTs * 1000),
    refresh: new Date(refreshTime),
    now: new Date(now),
    isTokenReachingExpiration,
  })
  return isTokenReachingExpiration
}
