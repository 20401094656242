import { AuthTokensOutput, JWTToken } from '@grandstand/presentation-models'
import { Logger } from '../../../utils/logger'
import { CurrentUser } from '../../StorageProviders/currentUserStore'
import { tokenRefreshBlockingFlag } from './tokenRefreshBlockingFlag'

const refreshTokenLogger = Logger.of('refreshToken')

interface RefreshTokenOpts {
  currentUserTokens: AuthTokensOutput
  refreshUrl: string
  setCurrentUser(response: CurrentUser): void
  f?: string
}

export const refreshToken = async ({
  currentUserTokens,
  refreshUrl,
  setCurrentUser,
}: RefreshTokenOpts): Promise<JWTToken> => {
  refreshTokenLogger.info('init')
  const { user_token, refresh_token } = currentUserTokens
  /*
    We want to do something like this in future:
    await waitForTokenClear
    const { user_token: new_user_token } = getCurrentUser
    if (new_user_token !== user_token) {
      // no refresh cause it just changed
      return new_user_token
    }
    ...continue refresh
  */
  if (tokenRefreshBlockingFlag.isBlocking()) {
    refreshTokenLogger.info('token blocking flag active')
    return user_token
  }
  refreshTokenLogger.info('token refreshing')
  let user: CurrentUser
  try {
    tokenRefreshBlockingFlag.setBlockingFlag()
    const response = await fetch(refreshUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user_token}`,
      },
      body: JSON.stringify({ refresh_token }),
    })
    user = await response.json()
    setCurrentUser(user)
    refreshTokenLogger.info('token refreshed')
  } catch (e) {
    refreshTokenLogger.info('error', e)
  } finally {
    tokenRefreshBlockingFlag.clearBlockingFlag()
  }
  return user?.user_token || ''
}
