import { AuthTokensOutput, JWTToken } from '@grandstand/presentation-models'
import TokenProvider from '..'
import { Logger } from '../../../utils/logger'
import { CurrentUser, isValidUser } from '../../StorageProviders/currentUserStore'
import { createUnauthenticatedUser } from './createUnauthenticatedUser'
import { isTokenReachingExpiration } from './isTokenReachingExpiration'
import { logout } from './logout'
import { refreshToken } from './refreshToken'

interface IWebTokenProvider {
  refreshUrl: string
  anonymousUrl: string
  logoutUrl: string
  getCurrentUser(): CurrentUser | undefined
  setCurrentUser(response: CurrentUser): void
  debug: boolean
}

const webTokenProviderLogger = Logger.of('webTokenProvider')

const webTokenProvider = (opts: IWebTokenProvider): TokenProvider => {
  const _refreshOrGetCurrentOrCreateUnauthToken = async (forceRefresh = false): Promise<JWTToken> => {
    const user = opts.getCurrentUser()
    if (!isValidUser(user)) {
      return await createUnauthenticatedUser({
        anonymousUrl: opts.anonymousUrl,
        setCurrentUser: opts.setCurrentUser,
      })
    }
    const { user_token, refresh_token } = user as AuthTokensOutput
    if (
      user_token &&
      opts.refreshUrl &&
      (forceRefresh ||
        isTokenReachingExpiration({
          token: user_token,
          debug: opts.debug,
        }))
    ) {
      return await refreshToken({
        currentUserTokens: {
          user_token,
          refresh_token,
        },
        refreshUrl: opts.refreshUrl,
        setCurrentUser: opts.setCurrentUser,
      })
    }
    return Promise.resolve(user_token)
  }

  return {
    forceRefresh: async (): Promise<JWTToken> => {
      webTokenProviderLogger.info('forceRefresh')
      const user_token = await _refreshOrGetCurrentOrCreateUnauthToken(true)
      return user_token
    },
    getToken: async (): Promise<JWTToken> => {
      webTokenProviderLogger.info('getToken')
      return new Promise((resolve) => _refreshOrGetCurrentOrCreateUnauthToken().then(resolve))
    },
    logout: async (): Promise<void> => {
      await logout({
        logoutUrl: opts.logoutUrl,
        setCurrentUser: opts.setCurrentUser,
      })
    },
  }
}
export { webTokenProvider }
