import { ItemStorage } from '../../StorageProviders/localStorageProvider'

// not a bool but a date string so that we can expire it
type TokenRefresh = false | string

const tokenRefreshingStorage = new ItemStorage<TokenRefresh>('tokenRefreshingOrCreating', false)

const isBlocking = () => {
  const refreshValue = tokenRefreshingStorage.getItem()
  // In case something went wrong with the last refresh/create, have a timeout of 30 seconds
  if (!refreshValue || Date.now() - new Date(refreshValue).valueOf() > 30 * 1000) {
    return false
  }
  return true
}

const setBlockingFlag = () => {
  tokenRefreshingStorage.setItem(new Date().toUTCString())
}

const clearBlockingFlag = () => {
  tokenRefreshingStorage.setItem(false)
}

const waitForBlockingFlagClear = async (): Promise<void> => {
  return new Promise((resolve) => {
    if (!isBlocking()) {
      resolve()
    }
    const intervalId = setInterval(() => {
      if (!isBlocking()) {
        clearInterval(intervalId)
        resolve()
      }
    }, 500)
  })
}

export const tokenRefreshBlockingFlag = {
  isBlocking,
  setBlockingFlag,
  clearBlockingFlag,
  waitForBlockingFlagClear,
}
