import { useEffect, useState } from 'react'

export enum ColorSchemeValue {
  light = 'light',
  dark = 'dark',
}

export type ColorSchemeState = {
  value: ColorSchemeValue
  isDark: boolean
  isLight: boolean
  ready: boolean
}

export const getColorSchemeValue = (): ColorSchemeValue => {
  if (window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? ColorSchemeValue.dark : ColorSchemeValue.light
  }
  return ColorSchemeValue.light
}
export const getColorScheme = (): ColorSchemeState => {
  const value = getColorSchemeValue()
  const isDark = value === 'dark'
  const isLight = value === 'light'
  return {
    value,
    isDark,
    isLight,
    ready: true,
  }
}

export const useColorScheme = (): ColorSchemeState => {
  const [colorScheme, setColorScheme] = useState<ColorSchemeState>(getColorScheme())
  useEffect(() => {
    const updateColorScheme = () => {
      setColorScheme(getColorScheme())
    }
    let unsubscribe = (): void => {}
    if (window.matchMedia) {
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)')
      colorSchemeQuery.addEventListener('change', updateColorScheme)
      unsubscribe = () => colorSchemeQuery.removeEventListener('change', updateColorScheme)
    } else {
      updateColorScheme()
    }
    return () => {
      unsubscribe()
      updateColorScheme()
    }
  }, [])

  return colorScheme
}
