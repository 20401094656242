import { JWTToken } from '@grandstand/presentation-models'
import { useState } from 'react'
import { ApiClient } from '../newPackages/ApiClient'
import { webTokenProvider } from '../newPackages/ApiClient/webTokenProvider'
import currentUserStore, { CurrentUser } from '../newPackages/StorageProviders/currentUserStore'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'

export type SetCurrentUser = (value: CurrentUser) => void

export const getIsDTC = (currentUser: CurrentUser = currentUserStore.getItem()): boolean => {
  const entitlements = currentUser?.profile.entitlements
  return entitlements !== undefined && entitlements.some((entitlement) => entitlement.type === 'dtc')
}

export const getIsLoggedIn = (user: CurrentUser = currentUserStore.getItem()): boolean => {
  return user?.profile.internal?.email !== undefined
}

export const updateUserTokens = (userToken: JWTToken, refreshToken: JWTToken) => {
  const user = currentUserStore.getItem()
  user && currentUserStore.setItem({ ...user, user_token: userToken, refresh_token: refreshToken })
}

export const useValidUser = (): readonly [CurrentUser, SetCurrentUser] => {
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [currentUser, setCurrentUserState] = useState(currentUserStore.getItem())

  const setCurrentUser = (user: CurrentUser) => {
    const newUser = currentUserStore.setItem(user)
    setCurrentUserState(newUser)
  }

  ApiClient.registerTokenProvider(
    webTokenProvider({
      refreshUrl: currentConfig.API.services.auth_services.refresh || '',
      anonymousUrl: currentConfig.API.services.auth_services.ballys.anonymous || '',
      logoutUrl: currentConfig.API.services.auth_services.ballys.logout || '',
      getCurrentUser: () => currentUserStore.getItem(),
      setCurrentUser: (item) => {
        setCurrentUser(item)
      },
      debug: false,
    })
  )

  return [currentUser, setCurrentUser]
}
