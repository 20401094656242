import { Logger } from '../../utils/logger'
import commonStrings from './dictionaries/common.json'

const logger = Logger.of('StringMgmt')

interface TStringJson {
  [key: string]: string
}

let _config: null | TStringJson = null

export const initializeStringMgmt = (app: 'web' | 'cw') => {
  addStrings(commonStrings)
  if (app === 'web') {
    import('./dictionaries/web.json').then((appStrings) => addStrings(appStrings.default))
  }
  if (app === 'cw') {
    import('./dictionaries/cw.json').then((appStrings) => addStrings(appStrings.default))
  }
  logger.debug(`Strings initialized`)
}

const addStrings = (config: TStringJson) => {
  if (_config === null) {
    _config = config
  } else {
    _config = { ..._config, ...config }
  }
}

const getString = (key: string): string => {
  if (_config === null) {
    logger.error('StringUtil has not been initialized.  You must call `addStrings` before calling `s`.')
    throw new Error('StringUtil has not been initialized')
  }
  if (!_config[key]) {
    logger.debug(`String for key \`${key}\` not found`)
    return key
  }
  return _config[key]
}

import { S } from './components/S'
export { S, addStrings, getString }
