import { useContext } from 'react'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'
import { GlobalStateContext } from '../services/config/GlobalStateService'

export type PurchasingDisabledType = {
  isPurchasingDisabled: boolean
  setIsPurchasingDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const usePurchasingDisabled = (): PurchasingDisabledType => {
  const configService = useContextUnconditionally(ConfigServiceContext)
  const {
    isPurchasingDisabled,
    actions: { setIsPurchasingDisabled },
  } = useContext(GlobalStateContext)
  // const [isPurchasingDisabled, setIsPurchasingDisabled] = useState(false)
  if (configService.currentConfig.API.services.subscription_services.purchasing_enabled === false) {
    setIsPurchasingDisabled(true)
  }
  return {
    isPurchasingDisabled,
    setIsPurchasingDisabled,
  }
}
