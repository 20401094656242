import { PropsWithChildren, ReactNode, createContext, useEffect, useMemo, useState } from 'react'

type ModalType = ReactNode | undefined
type ModalStore = {
  modal: ModalType
  isOpen: boolean
  closeModal: () => void
  openModal: (next: ReactNode) => void
}
export const ModalContext = createContext<ModalStore>(undefined as any)
type ModalProviderType = PropsWithChildren<{}>
export const ModalProvider = (props: ModalProviderType) => {
  const [modal, setModal] = useState<ModalType>(undefined)
  const isOpen = useMemo(() => !!modal, [modal])
  const closeModal = () => {
    setModal(undefined)
  }
  const openModal = (next: ReactNode) => {
    setModal(next)
  }
  useEffect(() => {
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      const { key, code } = event
      if ([key, code].some((str) => str === 'Esc' || str === 'Escape')) {
        setModal(undefined)
      }
    }
    document.body.addEventListener('keyup', handleKeyboardEvent)
    return () => {
      document.body.removeEventListener('keyup', handleKeyboardEvent)
    }
  }, [])
  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : ''
  }, [modal, isOpen])
  return (
    <ModalContext.Provider value={{ closeModal, openModal, modal, isOpen }}>
      <>{props.children}</>
      {modal}
    </ModalContext.Provider>
  )
}
