import { clientRuntimeConfig } from '../../config'
import { getWindowDeviceInfo } from '../getWindowDeviceInfo'
import { StandardLog } from './types'

export const createLog = (codeLocation: string, message: string, data: any): StandardLog => {
  const deviceInfo = getWindowDeviceInfo()
  return {
    message: `${codeLocation} > ${message}`,
    codeLocation,
    data: {
      ...(data || {}),
      webVersion: clientRuntimeConfig.rootVersion,
      deviceInfo,
    },
  }
}
