import { AuthProfileOutput } from '@grandstand/presentation-models'
import { Logger } from '../../utils/logger'
import { ItemStorage } from './localStorageProvider'

export type CurrentUser = AuthProfileOutput | undefined

const store = new ItemStorage<CurrentUser>('userService.currentUser', undefined)

const currentUserStoreLogger = Logger.of('currentUserStore')

export const isValidUser = (user: CurrentUser): boolean => !!user?.profile?.region

const currentUserStore = {
  getItem: store.getItem,
  setItem: (user: CurrentUser): CurrentUser => {
    currentUserStoreLogger.info('setCurrentUser', { user })
    if (user === undefined || isValidUser(user)) {
      store.setItem(user)
    } else {
      currentUserStoreLogger.error('setCurrentUser: user being set is not valid', {
        user,
      })
      if (!isValidUser(currentUserStore.getItem())) {
        currentUserStoreLogger.error('setCurrentUser: user in storage is also not valid, so resetting', {
          user,
        })
        store.setItem(undefined)
      }
    }
    return currentUserStore.getItem()
  },
}

export default currentUserStore
