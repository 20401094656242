import { NavBottomPortalWrapper } from './NavBottom'
import { NavMainPortalWrapper } from './NavMain'
import { NavTopPortalWrapper } from './NavTop'
import { useTopNavStore } from './TopNavContext'

export const TopNavPortals = () => {
  const store = useTopNavStore()

  return (
    <>
      <NavTopPortalWrapper id="nav-top-portal" ref={store.topRef} />
      <NavMainPortalWrapper id="nav-main-portal" ref={store.mainRef} />
      <NavBottomPortalWrapper
        ref={store.bottomRef}
        id="nav-bottom-portal"
        topHeight={store.topHeight}
        startItemHeight={store.startItemHeight}
      />
    </>
  )
}
