import { ItemStorage } from '@grandstand-web/bally-web-shared/src/newPackages/StorageProviders/localStorageProvider'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type StoredPartnerSource = {
  expiresAt: number
  value?: string
}

// expires: ms to wait after updating partner_source before clearing it (unless updated again)
const expires = 1000 * 60 * 60

const storage = new ItemStorage<StoredPartnerSource>('partner_source', {
  expiresAt: new Date().getTime() + expires,
})

function getPartnerSource(): StoredPartnerSource {
  let stored: StoredPartnerSource = storage.getItem()
  const now = new Date().getTime()
  if (now > stored.expiresAt) {
    stored = { expiresAt: now + expires, value: undefined }
    storage.setItem(stored)
  }
  return stored
}

export const usePartnerSource = () => {
  const router = useRouter()
  const [stored, setStored] = useState<StoredPartnerSource>(getPartnerSource())

  // clear any expired StoredPartnerSource
  useEffect(() => {
    setStored(getPartnerSource())
  }, [])

  // if router.query.partner_source is valid string: store it and remove it from router.query
  useEffect(() => {
    const { partner_source, ...query } = router.query

    if (typeof partner_source !== 'string') {
      return
    }

    if (partner_source.length) {
      const next: StoredPartnerSource = {
        expiresAt: new Date().getTime() + expires,
        value: partner_source,
      }
      storage.setItem(next)
      setStored(next)
    }

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    )
  }, [router])

  const setValue: Dispatch<SetStateAction<string | undefined>> = (valueOrGetValue) => {
    setStored((prev) => {
      const nextValue = typeof valueOrGetValue === 'function' ? valueOrGetValue(prev.value) : valueOrGetValue
      const next: StoredPartnerSource = {
        expiresAt: new Date().getTime() + expires,
        value: nextValue,
      }
      storage.setItem(next)
      return next
    })
  }
  return [stored.value, setValue] as const
}
