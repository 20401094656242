export const isValidZipcode = (s: string): boolean => {
  return /^\d{5}$/.test(s)
}

export const isValidEmail = (s: string): boolean => {
  return /^.+@.+$/.test(s)
}

export const ISO8601ToMonthDateFullYear = (iso8601: string): string => {
  const date = new Date(iso8601)
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}

export const USDCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
