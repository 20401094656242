import { CaptionLabel } from '@/styles/fonts'
import { GrandstandIcon, IconState } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import Link from 'next/link'
import { ReactNode } from 'react'
import styled from 'styled-components'

export type ToastState = 'success' | 'error' | 'link' | 'digest'

export interface ToastProps {
  state: ToastState
  iconRight?: Boolean
  iconLeft?: Boolean
  link?: string
  children: ReactNode
}

const ToastContainer = styled.div<ToastProps>`
  display: flex;
  max-width: 50%;
  height: 44px;
  padding: 12px;
  ${({ state }) =>
    state === 'link' || state === 'digest'
      ? `
       justify-content: inital;
      `
      : `justify-content: center;
      `};
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  z-index: 1000;
  background-color: ${({ state }) => {
    switch (state) {
      case 'success':
        return 'var(--success)'
      case 'error':
        return 'var(--error)'
      case 'link':
        return 'var(--canvas)'
      case 'digest':
        return 'var(--tint-pressed)'
    }
  }};
`
const ToastText = styled(CaptionLabel)<ToastProps>`
  color: ${({ state }) => {
    switch (state) {
      case 'error':
        return 'var(--white)'
      default:
        return 'var(--on-background)'
    }
  }};
`

const LinkToast = styled(Link)<ToastProps>`
  text-decoration: underline;
  color: var(--on-background);
  margin-left: 100px;
`

const Toast = ({ state, iconRight, iconLeft, children, link }: ToastProps) => {
  const rightlinkOrDigestIcon = iconRight && state !== 'link' && state !== 'digest'
  const iconType = state !== 'error' && state !== 'link' ? 'success' : 'error'
  return (
    <ToastContainer state={state}>
      {iconLeft && <GrandstandIcon icon={iconType} state={state as IconState} width="16px" />}
      <ToastText fontSize="sm" state={state}>
        {children}
      </ToastText>
      {link && (
        <LinkToast state={state} href={link}>
          <ToastText fontSize="sm" state={state}>
            Link
          </ToastText>
        </LinkToast>
      )}
      {rightlinkOrDigestIcon && <GrandstandIcon icon={iconType} state={state as IconState} width="16px" />}
    </ToastContainer>
  )
}

export default Toast
